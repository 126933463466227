<template>
  <b-card
    class="classroom-header mb-1"
    :img-src="headerData.classroomCover"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- profile navbar -->
    <div class="classroom-header-nav">
      <div class="p-2 d-flex align-items-center">
        <b-img
          class="classroom-img"
          :src="headerData.classroomLogo"
          rounded
          fluid
          alt="classroom photo"
        />
        <div class="w-100 ml-2 d-flex justify-content-between">
          <div>
            <h2>
              {{ headerData.classroomTitle }}
            </h2>
            <div class="d-flex">
              <b-badge class="mr-1" variant="light-info">
                <i class="ti ti-user"></i>
                {{ headerData.teacherName }}
              </b-badge>
              <b-badge class="mr-1 enrolled-badge" variant="light-success">
                <i class="ti ti-check"></i>
                Terdaftar
              </b-badge>
            </div>
          </div>
          <div class="enrolled-button">
            <b-button
              class="button d-flex align-items-center"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-success"
              disabled
            >
              <i class="ti ti-check"></i>
              <span class="align-middle">Terdaftar</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!--/ profile navbar -->
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 576px) {
  .classroom-header {
    .enrolled-badge {
      display: block;
    }
    .card-img-top {
      max-height: 100px;
    }
    .classroom-header-nav {
      .classroom-img {
        // display: none;
        border: 0.157rem solid #fff;
        width: calc(20vw);
        height: calc(20vw);
        margin-top: 0;
      }
    }
  }

  .enrolled-button {
    display: none;
  }
}

.enrolled-badge {
  display: none;
}
.card-img-top {
  max-height: 200px;
  object-fit: cover;
  // object-position: top;
}

.classroom-header {
  .classroom-img {
    border: 0.357rem solid #fff;
    background-color: #fff;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: -65px;
  }
}

.button {
  i {
    margin-right: 0.5rem;
  }
}
</style>
