<template>
  <div>
    <div v-if="isAuthorized && !isLoading">
      <classroom-header :header-data="headerData" />
      <classroom-content :classroom-content-data="classroomContent" />
    </div>

    <div v-if="!isAuthorized && !isLoading">
      <b-row>
        <b-col class="d-flex justify-content-center mt-4">
          <div class="text-center pt-4">
            <b-img class="illustration-small" fluid :src="emptyStateImg" />
            <p class="mb-2">
              Kelas yang anda cari tidak ditemukan
            </p>
            <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              :to="{
                name: checkAuthorizeRole('classroom'),
              }"
              >Cari Kelas Lain</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Loading Detail Classroom-->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Kelas
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { BRow, BCol, BButton, BImg, BSpinner } from 'bootstrap-vue'
import { checkAuthorizeRole } from '@/auth/utils'
import ClassroomHeader from './component/ClassroomHeader.vue'
import ClassroomContent from './component/ClassroomContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    ClassroomHeader,
    ClassroomContent,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      isAuthorized: false,

      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),

      classroomContent: {},
      headerData: {
        classroomTitle: 'class_title',
        classroomLogo: '/img/avatar-s-2.da5e73c7.jpg',
        teacherName: 'teacher_name',
        classroomCoverImg: 'https://picsum.photos/id/10/2500/1667',
      },
      classroomId: this.$router.currentRoute.params.id,
    }
  },
  methods: {
    checkAuthorizeRole,
  },
  async mounted() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    await axios
      .get(`${baseUrl}/students/classrooms/${this.classroomId}`, config)
      .then((response) => {
        const classroom = response.data.data

        this.headerData.classroomTitle = classroom.name
        this.headerData.classroomIcon = classroom.logo
        this.headerData.classroomLogo = classroom.image
        this.headerData.classroomCover = classroom.cover
        this.headerData.teacherName = classroom.teacher.name

        // sort content by week
        classroom.class_contents.sort((a, b) => {
          if (a.week > b.week) {
            return 1
          }
          if (a.week < b.week) {
            return -1
          }
          return 0
        })

        classroom.class_contents.forEach((content) => {
          content.tasks.map((task) => {
            if (task.is_exam === 1) {
              // eslint-disable-next-line no-param-reassign
              content.hasExam = true
            }
          })
        })

        this.classroomContent = classroom

        // set state
        this.isAuthorized = true
        this.isLoading = false
      })
      .catch((error) => {
        this.isLoading = false
        this.isAuthorized = false
      })
  },
}
</script>

<style lang="scss">
.card-img-overlay {
  padding: 0 !important;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}
</style>
