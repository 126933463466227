<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-1 ">
            <i class="text-primary ti ti-list-details small-gap-right" />
            <h6 class="text-primary mb-0">Akses cepat list materi</h6>
          </div>

          <!-- Quick Class Description -->
          <b-card
            bg-variant="transparent"
            class="border-light-secondary mb-1"
            :title="classroomContentData.name"
          >
            <b-card-text>
              {{ classroomContentData.description }}
            </b-card-text>
          </b-card>

          <!-- Announcement -->
          <div v-if="classroomContentData.announcement">
            <h5 class="font-weight-bolder py-1">
              Pengumuman
            </h5>
            <b-alert show variant="warning">
              <div class="alert-body p-2">
                <div class="d-flex">
                  <div class="mr-1">
                    <b-avatar rounded="lg" class="bg-white">
                      <i class="card-icon text-warning ti ti-bell" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h5
                      class="font-weight-bolder small-gap-bottom text-warning"
                    >
                      {{ classroomContentData.announcement.title }}
                    </h5>
                    <span class="font-weight-normal">
                      {{ classroomContentData.announcement.description }}
                    </span>
                  </div>
                </div>
              </div>
            </b-alert>
          </div>

          <!-- Material List -->
          <h5 class="font-weight-bolder py-1">
            Daftar Pertemuan
          </h5>
          <app-collapse type="default">
            <app-collapse-item
              v-for="(content, index) in classroomContentData.class_contents"
              :key="index"
              :title="`Pertemuan Ke-${index + 1} : ${content.title}`"
              :badge-text="index + 1 === 1 ? 'Sedang Berlangsung' : ''"
              :badge-variant="index + 1 === 1 ? 'light-warning' : ''"
              :is-visible="index + 1 === 1"
            >
              <span class="pb-1">
                {{ content.description }}
              </span>

              <b-row class="my-1">
                <b-col lg="12" class="small-gap-bottom">
                  <!-- Materi Card -->
                  <router-link
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-material'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <b-card
                      v-show="content.materials.length > 0"
                      bg-variant="transparent"
                      class="material-card border-light-secondary small-gap-bottom"
                    >
                      <div class="d-flex">
                        <div class="mr-1">
                          <b-avatar rounded="lg" variant="light-success">
                            <i class="card-icon ti ti-book-2 " />
                          </b-avatar>
                        </div>
                        <div class="d-flex flex-column w-100">
                          <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column ">
                              <!-- Dynamic Material Title -->
                              <span class="text-muted">Materi</span>
                              <h6 class="font-weight-bolder small-gap-bottom">
                                {{ content.title }}
                              </h6>
                            </div>
                            <div>
                              <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="flat-primary"
                                class="navigate-button"
                                :to="{
                                  name: checkAuthorizeRole(
                                    'classroom-detail-material'
                                  ),
                                  params: {
                                    id: classroomId,
                                    contentId: content.id,
                                  },
                                }"
                              >
                                <span class="align-middle mr-1">
                                  Lihat Detail
                                </span>
                                <i class="align-middle ti ti-arrow-right" />
                              </b-button>
                            </div>
                          </div>

                          <b-card-text>
                            {{
                              `Berikut merupakan materi yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                            }}
                            <span class="font-weight-bolder">
                              {{ content.title }}
                            </span>
                          </b-card-text>
                        </div>
                      </div>
                    </b-card>
                  </router-link>
                </b-col>
                <b-col lg="12" class="small-gap-bottom">
                  <!-- Task Card -->
                  <router-link
                    class="small-gap-bottom"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-task'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <b-card
                      v-show="content.tasks.length > 0"
                      bg-variant="transparent"
                      class="material-card border-light-secondary small-gap-bottom"
                    >
                      <div class="d-flex">
                        <div class="mr-1">
                          <b-avatar rounded="lg" variant="light-info">
                            <i class="card-icon ti ti-clipboard-list " />
                          </b-avatar>
                        </div>
                        <div class="d-flex flex-column w-100">
                          <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column ">
                              <span class="text-muted">Tugas</span>
                              <h6 class="font-weight-bolder small-gap-bottom">
                                {{ content.title }}
                              </h6>
                            </div>
                            <div>
                              <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="flat-primary"
                                class="navigate-button"
                                :to="{
                                  name: checkAuthorizeRole(
                                    'classroom-detail-task'
                                  ),
                                  params: {
                                    id: classroomId,
                                    contentId: content.id,
                                  },
                                }"
                              >
                                <span class="align-middle mr-1">
                                  Lihat Detail
                                </span>
                                <i class="align-middle ti ti-arrow-right" />
                              </b-button>
                            </div>
                          </div>

                          <b-card-text>
                            {{
                              `Berikut merupakan penugasan yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                            }}
                            <span class="font-weight-bolder">
                              {{ content.title }}
                            </span>
                          </b-card-text>
                        </div>
                      </div>
                    </b-card>
                  </router-link>
                </b-col>

                <b-col lg="12" class="small-gap-bottom">
                  <router-link
                    class="small-gap-bottom"
                    :to="{
                      name: checkAuthorizeRole('classroom-detail-forum'),
                      params: {
                        id: classroomId,
                        contentId: content.id,
                      },
                    }"
                  >
                    <!-- Forum Card -->
                    <b-card
                      v-show="content.forums.length > 0"
                      bg-variant="transparent"
                      class="material-card border-light-secondary"
                    >
                      <div class="d-flex">
                        <div class="mr-1">
                          <b-avatar rounded="lg" variant="light-warning">
                            <i class="card-icon ti ti-brand-hipchat " />
                          </b-avatar>
                        </div>
                        <div class="d-flex flex-column w-100">
                          <div class="d-flex justify-content-between">
                            <div class="d-flex flex-column ">
                              <span class="text-muted">Forum</span>
                              <h6 class="font-weight-bolder small-gap-bottom">
                                {{ content.title }}
                              </h6>
                            </div>
                            <div>
                              <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="flat-primary"
                                :to="{
                                  name: checkAuthorizeRole(
                                    'classroom-detail-forum'
                                  ),
                                  params: {
                                    id: classroomId,
                                    contentId: content.id,
                                  },
                                }"
                              >
                                <span class="align-middle mr-1">
                                  Lihat Detail
                                </span>
                                <i class="align-middle ti ti-arrow-right" />
                              </b-button>
                            </div>
                          </div>

                          <b-card-text>
                            {{
                              `Berikut merupakan forum Pertemuan ke-
                        ${index + 1} : `
                            }}
                            <span class="font-weight-bolder">
                              {{ content.title }}
                            </span>
                          </b-card-text>
                        </div>
                      </div>
                    </b-card>
                  </router-link>
                </b-col>

                <b-col lg="12">
                  <!-- Exam Card -->
                  <b-card
                    v-if="content.hasExam === true"
                    bg-variant="transparent"
                    class="material-card border-light-secondary mb-1"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar rounded="lg" variant="light-danger">
                          <i class="card-icon ti ti-file-text " />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column ">
                            <span class="text-muted">Ujian</span>
                            <h6 class="font-weight-bolder small-gap-bottom">
                              {{ content.title }}
                            </h6>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'classroom-detail-exam'
                                ),
                                params: {
                                  id: classroomId,
                                  contentId: content.id,
                                },
                              }"
                            >
                              <span class="align-middle mr-1">
                                Lihat Detail
                              </span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{
                            `Berikut merupakan ujian yang akan dibahas pada Pertemuan ke-
                        ${index + 1} : `
                          }}
                          <span class="font-weight-bolder">
                            {{ content.title }}
                          </span>
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>

              <!-- Created Date -->
              <span class="text-muted mt-1">
                Dibuat pada 12/12/2022
              </span>
            </app-collapse-item>
          </app-collapse>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { checkAuthorizeRole } from '@/auth/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  props: {
    classroomContentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      classroomId: this.$router.currentRoute.params.id,
    }
  },
  methods: {
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    // background-clip: border-box;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
